@tailwind base;
@tailwind components;
@tailwind utilities;

/* #main::before {
  @apply absolute top-0 left-0 z-10 h-full w-full bg-black opacity-40;
  content: "";
}

#main>* {
  @apply relative z-20;
} */

.simple-keyboard {
  max-width: 850px;
}

.simple-keyboard.myTheme1,
.simple-keyboard.myTheme2 {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
}

.simple-keyboard.myTheme2 {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  bottom: 0;
}

.simple-keyboard.myTheme1 .hg-button {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: 2px solid #b5b5b5;
  font-size: 2rem;
  width: 0;
}

.simple-keyboard.myTheme2 .hg-button {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: 2px solid #b5b5b5;
  font-size: 2rem;
}

.simple-keyboard.myTheme1 .hg-button:active,
.simple-keyboard.myTheme2 .hg-button:active {
  background: #1c4995;
  color: white;
}

#root .simple-keyboard.myTheme1 + .simple-keyboard-preview,
#root .simple-keyboard.myTheme2 + .simple-keyboard-preview {
  background: #1c4995;
}

.no-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: black black;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #000;
}

::-webkit-scrollbar-corner {
  background: #000;
}

.rc-time-picker .rc-time-picker-input {
  width: 18rem;
  height: 4rem;
  font-size: 20px;
  color: black;
}

.rc-time-picker .rc-time-picker-clear {
  display: none;
}
